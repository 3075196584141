<template>
<div id="page" class="clinic-static-and-export">
  <el-row class="csae-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item label="截止日期" prop="date">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.date" :disabled-date="disabledDate" type="date" :default-value="[defaultDate]" :clearable="false" placeholder="选择日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">加载数据</el-button>
          <el-button type="info" :disabled="(table.tableData||[]).length==0" @click="exportTo()">导出到csv</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-row>
  <el-table :data="table.tableData" show-summary sum-text="合计" v-loading="table.tableLoading">
    <el-table-column prop="txt_clinic_name" label="诊所名称" align="center" />
    <el-table-column prop="txt_area_name" label="所在地" align="center" />
    <el-table-column prop="txt_type_name" label="设备名称" align="center" />
    <el-table-column prop="cnt_order" label="至今检测次数" align="center" />
    <el-table-column prop="sum_order_amount" label="终端收费（元）" align="center">
        <template #default="scope">
          <template v-if="scope.row.sum_order_amount">￥{{scope.row.sum_order_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
    <el-table-column prop="sum_profit_amount" label="轻盈分成收入（元）" align="center">
        <template #default="scope">
          <template v-if="scope.row.sum_profit_amount">￥{{scope.row.sum_profit_amount}}</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
  </el-table>
</div>
</template>

<script>
// 财务管理 - 诊所统计
import { ElMessage } from 'element-plus';
import { exportToCsv } from "assets/js/export";
import { computed, onBeforeMount, onMounted, reactive, toRefs } from 'vue';
import { getClinicStatisData, getSystemTime } from "api/apis.js";
export default {
  setup() {
    let moment = require("moment");
    const state = reactive({
      form: {
        date: ""
      },
      table: {
        tableData: [],
        tableLoading: false
      },
      dateRange: [],
      defaultDate: computed(() => moment(new Date()).format('YYYY-MM-DD'))
    });

    onBeforeMount(() => {
      getDate();
    })

    onMounted(() => {
      state.form.date = state.defaultDate;
      getList();
    });

    // 导出
    const exportTo = () => {
      exportToCsv(state.table.tableData);
    };

    // 获取系统日期
    const getDate = () => {
      getSystemTime().then(response => {
        if (response.code == 200) {
          state.dateRange = [response.data.dt_min, response.data.dt_max];
        }
      })
    };

    // 获取数据
    const getList = () => {
      state.table.tableLoading = true;
      getClinicStatisData({ dt_till: state.form.date }).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    // 禁止选择的日期
    const disabledDate = (time) => {
      if ((state.dateRange || []).length != 0) {
        return time.getTime() > Date.now() || time.getTime() < new Date(state.dateRange[0]).getTime();
      } else {
        return time.getTime() > Date.now();
      }
    };

    return {
      ...toRefs(state),
      getList,
      disabledDate,
      getDate,
      exportTo
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-static-and-export {
  height: 100%;

  .el-table {
    height: calc(100% - 95px);
    overflow: visible;
  }
}
</style>
